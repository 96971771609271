<script setup lang="ts">
const { menuItems } = useMenu();
</script>

<template>
  <nav
    class="app-header-menu grow flex flex-col items-stretch justify-center h-12"
  >
    <ul
      class="app-header-menu__wrapper grow flex items-center justify-between gap-1 2xl:gap-4"
    >
      <li v-for="item in menuItems" class="app-header-menu__item">
        <AppHeaderMenuItem v-bind="{ item }" />
      </li>
    </ul>
  </nav>
</template>
